import axios from "axios";
import Vue, {Component} from "vue";
import Router from 'vue-router'
import {createPinia, PiniaVuePlugin} from 'pinia'
import VueApollo from 'vue-apollo'
import {Logger} from '@hc/base'
import Session from "supertokens-web-js/recipe/session"
import {AppContext} from '@hc/graphql'
import {useAppStore} from "./store"
import {DefaultApolloClient} from '@vue/apollo-composable'
import {HcUiVue, vueContext} from "../vue";
import VueI18n from 'vue-i18n'
import {createI18n, castToVueI18n} from 'vue-i18n-bridge'
import {i18n} from "@hc/base"
import Uri from "urijs";
import AuthSupertokens from "../auth/auth_supertokens"

const logger = new Logger('Boot')

export interface AppBootParams {
  appName: string
  scope: string
  api: AppContext
  root: Component
  router: Router
  configUrl: string
  store?: any,
  uiPath?: string,
  beforeBoot?: (vue: any) => Promise<void>
  afterBoot?: (vue: any) => Promise<void>
  jwtAllowed?: (jwt: any) => boolean
}

export async function bootstrapApp(params: AppBootParams){

  logger.log('Bootstrap params=%o', params)

  // set default params
  params = {
    uiPath: '/',
    ...params
  }

  const {api, router, appName, scope, root, store} = params
  logger.log('Loading server configuration url=%s', params.configUrl)
  const serverConfig = await loadServerConfig(params.configUrl)

  logger.log('Applying config', serverConfig)
  Object.assign(api.config, serverConfig)

  // configure crud schema
  api.schema.addForms(serverConfig.schema.forms)
  api.schema.addTables(serverConfig.schema.tables)
  api.schema.setApolloClient(api.apollo)

  await AuthSupertokens.init(scope, serverConfig)

  logger.log('Starting vuejs app')
  Vue.config.productionTip = false
  Vue.use(VueApollo)
  Vue.use(PiniaVuePlugin)
  Vue.use(Router)
  Vue.use(HcUiVue)
  Vue.use(VueI18n, {bridge: true})

  // guard routes that need authentication
  router.beforeEach(async (to, from, next) => {
    const isProtected = !to.meta.noAuth
    const hasToken = new Uri(window.location).hasQuery('token')
    if(hasToken){
      next()
    } else {
      const hasSession = await Session.doesSessionExist()
      if(isProtected && !hasSession){
        window.localStorage[`${appName}.${scope}.afterLogin`] = to.fullPath
        window.location.href = params.uiPath+'?login'
      } else {
        next()
      }
    }
  })
  let sessionExpiryProcessed = false
  // handle jwt expiry in apollo graphql
  api.on('apollo:graphql-error', (event, target) => {
    if(sessionExpiryProcessed) return
    if(target.extensions.code && target.extensions && target.extensions.code == 'invalid-jwt'){
      sessionExpiryProcessed = true
      if(router.currentRoute){
        window.localStorage[`${appName}.${scope}.afterLogin`] = router.currentRoute.fullPath
      }
      Session.attemptRefreshingSession().then((refreshed)=>{
        if(!refreshed){
          window.location.href = params.uiPath
        } else {
          window.location.reload()
        }
      })
    }
  })



  if(params.beforeBoot) {
    await params.beforeBoot(Vue)
  }

  const i18nBridge = castToVueI18n(createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: i18n.messages
  }, VueI18n))

  Vue.use(i18nBridge)

  // construct vue instance
  const vue = new Vue({
    router,
    store,
    i18n: i18nBridge,
    pinia: createPinia(),
    apolloProvider: new VueApollo({
      defaultClient: api.apollo,
    }),
    provide: {
      [DefaultApolloClient]: api.apollo,
    },
    render: (h) => h(root),
  })

  // set global vue context
  vueContext.root = vue
  vueContext.vuex = store

  // mount app
  vue.$mount('#app')

  // boot async so the loading screen will not freeze
  vue.$nextTick(async ()=>{
    logger.log('Initializing stores')
    const app = useAppStore()
    await app.boot(params)
    logger.log('Bootstrap complete')
    const loader = document.getElementById('app-loader')
    if(loader) loader.remove()
  })
}


export async function loadServerConfig(configUrl: string, retryCount: number = 10): Promise<Record<string, any>> {
  if(retryCount == 0) throw Error("Error loading server configuration!")
  try {
    const result = await axios.get(configUrl)
    const serverConfig = result.data
    return serverConfig
  } catch (e) {
    return new Promise((resolve, reject) => {
      window.setTimeout( async ()=> {
        const result = await loadServerConfig(configUrl, retryCount - 1)
        resolve(result)
      }, 1000)
    })
  }
}

