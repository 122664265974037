import {defineStore} from "pinia"
import {AppContext} from "@hc/graphql"
import {ref, computed} from "vue"
import _ from 'lodash'

export const useConfigStore = defineStore('config', () => {
  const settings = ref<Record<string, any>>({})
  const app = ref<string>(null)
  const scope = ref<string>(null)
  const uiPath = ref<string>(null)
  const api = ref<AppContext>(null)
  const environment = process.env.NODE_ENV || 'development'

  function get(name: string, defaultValue?: any){
    const value = _.get(settings.value, name, defaultValue)
    return value
  }

  return {
    settings,
    app,
    scope,
    uiPath,
    api,
    get,
    environment,
    isProd: environment == 'production',
    isDev: environment == 'development'
  }

})