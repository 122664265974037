import {isValidPhoneNumber} from "libphonenumber-js";

export const FORMULATE_PLUGIN_OPTIONS = {
  library: {
    attributes: {
      component: 'FormulateDynamicAttributes',
      slotProps: {
        component: ['definition'],
      }
    },
    relationAssociation: {
      component: 'FormulateRelationAssociation',
      slotProps: {
        component: ['collection', 'remote', 'owner'],
      }
    },
    joinComposition: {
      component: 'FormulateJoinComposition',
      slotProps: {
        component: ['collection', 'fields']
      }
    },
    joinAssociation: {
      component: 'FormulateJoinAssociation',
      slotProps: {
        component: ['collection', 'constrain']
      }
    },
    radioButtons: {
      component: 'FormulateRadioButtons',
    }
  },
  rules: {
    phone: ({value}) => isValidPhoneNumber(value, 'DE'),
    vatValidation: ({value, getFormValues}) => {
      const {type} = getFormValues()
      if (type === 'owner' && !value) return false
    }
  },
  locales: {
    en: {
      phone: ({value}) => `Phone number ${value} is not valid`,
      vatValidation: ({value, getFormValues}) => {
        const {type} = getFormValues()
        if (type === 'owner' && !value) return 'Vat ID is required for owner company.'
      }
    }
  },
}