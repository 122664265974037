import {defineStore} from "pinia"
import {ref} from "vue"

export const useCounterStore = defineStore('counter', () => {
  const count = ref(0)
  const name = ref('Farzan')
  function increment(){
    this.count++
  }
  return {count, name, increment}
})

/**
 {
  state: () => ({
    count: 0,
    name: 'Eduardo'
  }),
  actions: {
    increment() {
      this.count++
    },
  },
  getters: {
    doubleCount: (state) => state.count * 2,
  },
}
 */