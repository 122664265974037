import ThirdParty from "supertokens-web-js/recipe/thirdparty"
import Passwordless from "supertokens-web-js/recipe/passwordless"
import EmailPassword from "supertokens-web-js/recipe/emailpassword"
import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";


const AuthSupertokens = {
  async init(scope: string, config: any){
    SuperTokens.init({
      appInfo: {
        appName: config.auth.appName,
        apiDomain: config.services.core,
        apiBasePath: config.auth.apiBasePath,
      },
      recipeList: [
        ...this.initRecipes(scope, config.auth.providers),
        Session.init({}),
      ]
    })
  },
  initRecipes(scope: string, providers: Record<string, string[]>){
    return providers[scope].map((provider) => {
      switch(provider){
        case 'passwordless': return this.initPasswordless(scope)
        case 'microsoft': return this.initMicrosoft(scope)
        default: throw new Error('Unknown supertokens recipe provide: ' + provider)
      }
    })
  },
  initPasswordless(scope?: string) {
    return Passwordless.init({
      preAPIHook: async (context) => {
        return AuthSupertokens.setScopeHeader(context, scope)
      }
    })
  },
  initEmailPassword(scope?: string) {
    return EmailPassword.init({
      preAPIHook: async (context) => {
        return AuthSupertokens.setScopeHeader(context, scope)
      },
    })
  },
  initMicrosoft(scope?: string) {
    return ThirdParty.init({
      preAPIHook: async (context) => {
        return AuthSupertokens.setScopeHeader(context, scope)
      },
    })
  },
  async getRedirectUrl(context, baseUrl: string, afterloginPath: string, scope?: string) {
    if (context.action === "SUCCESS") {
      if (context.redirectToPath !== undefined) {
        // we are navigating back to where the user was before they authenticatedp
        return context.redirectToPath
      }
      if (scope) return `${baseUrl}/${scope}${afterloginPath}`

      return `${baseUrl}${afterloginPath}`
    }
    return undefined
  },
  setScopeHeader(context, scope: string) {
    const url = context.url
    const requestInit = context.requestInit
    if (scope) requestInit.headers.scope = scope
    return {url, requestInit}
  },
}

export default AuthSupertokens


