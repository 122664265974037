import _ from 'lodash'
import Vue from 'vue'
import {Store} from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import VueMultiselect from 'vue-multiselect'
import JsonPretty from 'vue-json-pretty'
import Draggable from 'vuedraggable'
import VSelect from 'vue-select'
import VueMoment from 'vue-moment'
import VueFormulate from '@braid/vue-formulate'
//import Datepicker from "./crud/editors/Datepicker.vue"
import DateRangePicker from 'vue2-daterange-picker'
import VueFormGenerator from 'vue-form-generator'

import {currentYear, formatCurrency, formatDate, formatDatetime, formatTime, objectPath, titleize, camelize} from "@hc/graphql"

import 'bootstrap/dist/js/bootstrap.bundle'
import 'vue-json-pretty/lib/styles.css'
import 'vue-multiselect/dist/vue-multiselect.min'
import 'vue-select/dist/vue-select.css'
import './fontawesome'
import {FORMULATE_PLUGIN_OPTIONS} from "./form"

export const vueContext = {
  root: null as Vue,
  vuex: null as Store<any>,
  components: {} as Record<string, any>,
  filters: {} as Record<string, Function>,
  plugins: [],
  addPlugin(plugin: any, options: any = null){
    this.plugins.push({plugin, options})
  },
  addPlugins(plugins: Array<any>){
    plugins.map((plugin) => this.plugins.push({plugin, options: {}}))
  },
  addComponents(components: Record<string, any>){
    Object.assign(this.components, components)
  },
  addComponent(name: string, component: any){
    this.components[name]=component
  },
  addFilters(filters: Record<string, Function>){
    Object.assign(this.filters, filters)
  },
  addFiltert(name: string, filter: Function){
    this.filters[name]=filter
  },
  importComponents(map: Record<string, any>, prefix = '', debug = false){
    const paths = Object.keys(map)
    const names = []
    for(const path of paths){
      const name = prefix + path.split('/').pop().replace(/\.vue$/, '')
      const component = map[path]
      if(debug) console.log("  ----->", name, path)
      this.components[name]=component
      names.push(name)
    }
    names.sort()
    if(debug) console.log(`Imported ${names.length} components: %o`, names)
  },
  importVfgFields(map: Record<string, any>, debug = false){
    this.importComponents(map, 'field', debug)
  },
  install(vue: any){
    for(const name of Object.keys(this.filters)){
      vue.filter(name, this.filters[name])
    }
    for(const name of Object.keys(this.components)){
      vue.component(name, this.components[name])
    }
    for(const plugin of this.plugins){
      vue.use(plugin)
    }
  }
}

export const HcUiVue = {
  install() {
    // register plugins, components and filters
    _.map(vueContext.plugins, ({plugin, options}) => Vue.use(plugin, options))
    _.map(vueContext.components, (target, name) => Vue.component(name, target))
    _.map(vueContext.filters, (target, name) => Vue.filter(name, target))
  }
}

vueContext.addComponents({
  VueMultiselect,
  JsonPretty,
  Draggable,
  VSelect,
  //Datepicker,
  DateRangePicker
})

vueContext.addFilters({
  formatDate,
  formatDatetime,
  formatTime,
  formatCurrency,
  currentYear,
  objectPath,
  titleize,
  camelize
})

vueContext.addPlugins([
  BootstrapVue,
  VueMoment,
  VueFormGenerator,
])

vueContext.addPlugin(VueFormulate, FORMULATE_PLUGIN_OPTIONS)


export default HcUiVue