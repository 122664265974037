import {defineStore} from "pinia"
import {computed} from "vue"
import {useConfigStore} from "./config"


export const useCrudStore = defineStore('crud', () => {
  const config = useConfigStore()
  const collections = computed(()=> config.api.crudCollections)
  const schema = computed(()=> config.api.schema)
  return {collections, schema}
})