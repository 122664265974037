import * as uuid from 'uuid'
import {defineStore} from "pinia"
import {vueContext} from '@hc/ui/vue'
import {BvToastOptions, BvToast, BvModal, BvMsgBoxOptions} from 'bootstrap-vue'

export interface Modal {
  id?: string,
  title: string,
  success?: boolean,
  info?: string,
  warning?: string,
  error?: string,
  component?: string,
  params?: any,
  result?: any,
  config?: Record<string, any>,
  handler?: any
}

const TOAST_DEFAULT_OPTIONS = {
  toaster: 'b-toaster-bottom-left',
  solid: true,
}

const MODAL_DEFAULT_OPTIONS = {
}

export const useUiStore = defineStore('ui', {
  state: () =>({
    modals: [] as Array<Modal>
  }),
  getters: {
    bvToast: () => vueContext.root.$bvToast as BvToast,
    bvModal: () => vueContext.root.$bvModal as BvModal,
    modal(){
      return this.modals[0]
    },
  },
  actions: {
    notify(message: string, options: BvToastOptions = {}){
      this.bvToast.toast(message, {...TOAST_DEFAULT_OPTIONS, ...options})
    },
    success(message: string, options: BvToastOptions = {}){
      this.notify(message, {title: 'Info', ...options, variant: 'success'})
    },
    warning(message: string, options: BvToastOptions = {}){
      this.notify(message, {title: 'Warning', ...options, variant: 'warning'})
    },
    error(message: string, options: BvToastOptions = {}){
      this.notify(message, {title: 'Error', ...options, variant: 'danger'})
    },
    async confirm(message: string, options: BvMsgBoxOptions = {}) {
      return await this.bvModal.msgBoxConfirm(message, {...MODAL_DEFAULT_OPTIONS, ...options})
    },
    async alert(message: string, options: BvMsgBoxOptions = {}) {
      return await this.bvModal.msgBoxOk(message, {...MODAL_DEFAULT_OPTIONS, ...options})
    },
    async openModal(modal: Modal): Promise<any>{
      return new Promise((resolve, reject) => {
        if(!modal.id) modal.id = uuid.v4()
        this.modals.unshift(modal)
        const innerHandler = modal.handler
        modal.handler = (result, modal) => {
          if(innerHandler) innerHandler(result, modal)
          resolve(result)
        }
      })
    },
    async closeModal(){
      if(!this.modal) return
      if(this.modal.success){
        this.modal.handler(this.modal.result, this.modal)
      } else {
        this.modal.handler(null, this.modal)
      }
      this.modals.shift()
    }
  }
})