import {defineStore} from "pinia"
import {ref, Ref} from "vue"
import {Logger} from '@hc/base'
import {has} from "lodash";

const logger = new Logger('CronStore')

export type CronJobHandler = ()=>void
export type CronJobParams = {
  name: string
  timeout: number
  handler: CronJobHandler
}

export type CronJob = CronJobParams & {
  lastRun: number
  running: boolean
  wrapper: CronJobHandler
  active: boolean
}

export const useCronStore = defineStore('cron', () => {
  const jobs = ref<CronJob[]>([]) as Ref<CronJob[]>
  const active = ref<boolean>(true)
  const documentFocus = ref<boolean>(true)
  const documentVisible = ref<boolean>(true)
  function register(params: CronJobParams): CronJob {
    const job = {
      ...params,
      lastRun: null,
      running: false,
      wrapper: null,
      active: true,
    }
    logger.log('Register job=%s timeout=%i', job.name, job.timeout)
    const oldJobIndex = jobs.value.findIndex((j) => j.name == job.name)
    if(oldJobIndex >= 0){
      logger.log('Removing job=%s', job.name)
      jobs.value.splice(oldJobIndex, 1)
    }
    jobs.value.push(job)
    job.wrapper = async ()=>{
      if(job.running){
        logger.log(`Job already running, skip execution job=%s`, job.name)
        return
      }
      logger.log(`Run job=%s`, job.name)
      try {
        job.running = true
        await job.handler()
        logger.log(`Finished job=%s`, job.name)
      } catch(e){
        logger.exception(e, 'Job execution failed job=%s', job.name)
      } finally {
        job.running = false
        job.lastRun = Date.now()
      }
      if(active.value){
        if(!job.active){
          logger.log(`Job not active job=%s`, job.name)
        } else {
          logger.log(`Schedule next run job=%s`, job.name)
          window.setTimeout(job.wrapper, job.timeout)
        }
      } else {
        logger.log(`Window not active, postponing schedule of job=%s`, job.name)
      }
    }
    logger.log(`Schedule initial timeout job=%s`, job.name)
    window.setTimeout(job.wrapper, job.timeout)
    return job
  }

  function unregister(job: CronJob){
    logger.log('Unregister job=%s', job.name)
    const jobIndex = jobs.value.findIndex((j) => j.name == job.name)
    if(jobIndex>=0){
      jobs.value.splice(jobIndex, 1)
    }
  }


  async function activate(){
    logger.log('Activating jobs')
    active.value = true
    for(const job of jobs.value){
      await job.wrapper()
    }
  }

  async function deactivate(){
    logger.log('Deactivating jobs')
    active.value = false
  }

  async function processWindowFocus(){
    const hasFocus = document.hasFocus()
    const hadFocus = documentFocus.value
    logger.log('Focus changed was=%s is=%s active=%s', hasFocus, hadFocus, active)
    documentFocus.value = hasFocus
    if(!hadFocus && hasFocus){
      if(!active.value) await activate()
    } else if(hadFocus && !hasFocus){
      if(active.value) await deactivate()
    }
  }

  async function processDocumentVisibility(){
    const isVisible = document.visibilityState == 'visible'
    const wasVisible = documentVisible.value
    logger.log('Visibility changed is=%s was=%s active=%s', isVisible, wasVisible, active)
    documentVisible.value = isVisible
    if(!wasVisible && isVisible){
      if(!active.value) await activate()
    } else if(wasVisible && !isVisible){
      if(active.value) await deactivate()
    }
  }

  document.addEventListener('visibilitychange', processDocumentVisibility, false)
  window.addEventListener('blur', processWindowFocus, false)
  window.addEventListener('focus', processWindowFocus, false)

  return {jobs, register, unregister, active}
})